import i18n from 'i18next';

const getTranslatedProperty = (key: string, object: Record<any, any>, noFallback: boolean = false) => {
    const iso = i18n.language ?? '';
    const fallback = noFallback ? '' : object[key];

    switch (iso) {
    case 'en':
        return object[`${key}_en`] ?? fallback;

    default:
        return object[key];
    }
};

export { getTranslatedProperty };