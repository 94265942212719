import moment from "moment";
import { PathStatus } from "../../enums";

export enum LengthFilterOption {
    ZeroToThree = 'zeroToThree',
    ThreeToSix = 'threeToSix',
    SixToTen = 'sixToTen',
    TenPlus = 'tenPlus',
};

export const lengthFilterEvaluator = (entry: any, option?: string): boolean => {
    const {length} = entry;

    switch (option) {
        case LengthFilterOption.ZeroToThree:
            return length >= 0 && length <= 3;

        case LengthFilterOption.ThreeToSix:
            return length >= 3 && length <= 6;

        case LengthFilterOption.SixToTen:
            return length >= 6 && length <= 10;

        case LengthFilterOption.TenPlus:
            return length >= 10;

        default:
            return true;
    }
};

export enum StatusFilterOption {
    Open = 'open',
    Closed = 'closed',
    GroomedToday = 'groomedToday',
    GroomedLatestYesterday = 'groomedLatestYesterday',
};

export const statusFilterEvaluator = (entry: any, option?: string): boolean => {
    const {status, groomedAt} = entry;

    const now = moment();
    const groomedDate = moment(groomedAt);

    switch (status) {
        case PathStatus.Closed:
            switch (option) {
                case StatusFilterOption.Closed:
                case undefined:
                    return true;

                default:
                    return false;
            }
        
        case PathStatus.Open:
            switch (option) {
                case StatusFilterOption.Closed:
                    return false;

                case StatusFilterOption.GroomedToday:
                    return groomedDate.isSame(now.clone().startOf('day'), 'd');

                case StatusFilterOption.GroomedLatestYesterday:
                    return groomedDate.isSameOrAfter(now.clone().subtract(1, 'days').startOf('day'), 'd');

                default:
                    return true;
            }

        // Only show options that have no status if no option is selected
        default:
            return option === undefined;
    }
};

export enum DifficultyFilterOption {
    VeryEasy = 'veryEasy',
    Easy = 'easy',
    Medium = 'medium',
    Hard = 'hard',
};

export const difficultyFilterEvaluator = (entry: any, option?: string): boolean => {
    const { difficulty } = entry;
    return !option || option === difficulty;
};