import { StyleSheet } from "react-native";
import { Theme } from "../theme-provider";

const filtersStyle = (theme: Theme) => StyleSheet.create({
    container: {
        flexDirection: 'column',
        alignItems: 'stretch',
    },

    crop: {
        overflow: 'hidden',
    },

    filterOptions: {
        position: 'absolute',
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap',
        backgroundColor: theme.color.listBackgroundInverted,
        padding: theme.spacing.standard,
        paddingLeft: theme.spacing.sides,
        paddingRight: theme.spacing.sides,
    },

    filterOption: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '50%',
    },

    fullWidth: {
        width: '100%',
    },

    filterOptionText: {
        color: theme.color.textInverted,
    },

    filterOptionIcon: {
        width: theme.iconSize.medium,
        height: theme.iconSize.medium,
        marginRight: theme.spacing.half,
    },

    filterDropdowns: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: theme.color.listEntryBackground,
        paddingLeft: theme.spacing.sides,
        paddingRight: theme.spacing.sides,
    },

    filterDropdown: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: theme.spacing.half,
        paddingBottom: theme.spacing.half,
        paddingRight: theme.spacing.standard,
    },

    filterDropdownText: {
        color: theme.color.textSecondary,
    },

    dropdownIconContainer: {
        marginLeft: theme.spacing.sliver,
        marginTop: theme.spacing.sliver,
    },

    dropdownIconContainerActive: {
        transform: [{ scaleY: -1 }],
    },

    filterDropdownIcon: {
        color: theme.color.textSecondary,
        width: theme.iconSize.extraSmall,
        height: theme.iconSize.extraSmall,
    },
});

export { filtersStyle };