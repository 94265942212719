import React, { useCallback, useRef, useState, useMemo } from 'react';
import { Animated, Easing, LayoutChangeEvent, TouchableOpacity, View, ViewProps } from 'react-native';
import { ActiveFilters, Filter } from '../../interfaces';
import { withExternalStyle } from '../../utils';
import { useThemedStyleFunction } from '../theme-provider';
import { ThemedText } from '../themed-text';
import { filtersStyle } from './filters.style';
import { DropdownArrow } from '../../assets';

type FilterProps = ViewProps & {
    filters: Filter[],
    onChange?: (activeFilters: ActiveFilters) => void,
}

const Filters = (props: FilterProps) => {
    const {style: styleProp, filters} = props;
    
    const orderedFilters = useMemo(() => filters.map((filter) => ({
        name: filter.name,
        unsetName: filter.unsetName,
        identifier: filter.identifier,
        options: filter.options.map((_, index, self) => {
            const half = index / 2;
            const shift = index % 2 === 0 ? 0 : self.length / 2;
            const shiftedIndex = Math.floor(half + shift);
            
            return self[shiftedIndex];
        }),
    })), [filters]);

    const [selectedDropdown, setSelectedDropdown] = useState(0);
    const [selectedOptions, setSelectedOptions] = useState<{[key: string]: number}>(
        orderedFilters.reduce((acc, curr) => ({
            ...acc, [curr.identifier]: -1,
        }),
    {}));
    const [maxHeight, setMaxHeight] = useState(0);
    const [filtersVisible, setFiltersVisible] = useState(false);
    const filtersAnimation = useRef(new Animated.Value(0)).current;

    const style = useThemedStyleFunction(filtersStyle);
    const containerStyle = withExternalStyle(style.container, styleProp);

    const emitActiveFilters = () => {
        const {onChange} = props;

        const activeFilters: ActiveFilters = {};
        orderedFilters.forEach((filter, index) => {
            const selectedIndex = selectedOptions[filter.identifier];
            if (selectedIndex !== -1) {
                activeFilters[filter.identifier] = filter.options[selectedIndex].value;
            }
        });
        onChange?.(activeFilters);
    };

    const updateFiltersVisibility = (visible: boolean) => {
        Animated.timing(filtersAnimation, {
            toValue: visible ? 1 : 0,
            duration: 250,
            easing: Easing.ease,
            useNativeDriver: false,
        }).start();

        setFiltersVisible(visible);
    };

    const updateSelectedOption = useCallback((dropdownIndex: number, optionIndex: number) => () => {
        const {onChange} = props;

        const identifier = orderedFilters[dropdownIndex].identifier;
        const newSelectedOptions = {...selectedOptions, [identifier]: optionIndex};
        setSelectedOptions(newSelectedOptions);
        updateFiltersVisibility(false);

        const activeFilters: ActiveFilters = {};
        orderedFilters.forEach((filter) => {
            const selectedIndex = newSelectedOptions[filter.identifier];
            if (selectedIndex !== -1) {
                activeFilters[filter.identifier] = filter.options[selectedIndex].value;
            }
        });

        onChange?.(activeFilters);
    }, [selectedOptions, setSelectedOptions, updateFiltersVisibility, emitActiveFilters]);

    const updateSelectedDropdown = useCallback((dropdownIndex: number) => () => {
        setSelectedDropdown(dropdownIndex)

        updateFiltersVisibility(!filtersVisible || dropdownIndex !== selectedDropdown);
    }, [setSelectedDropdown, updateFiltersVisibility]);

    const updateMaxHeight = useCallback((event: LayoutChangeEvent) => setMaxHeight(event?.nativeEvent?.layout?.height ?? 0), [setMaxHeight]);

    const filtersHeight = filtersAnimation.interpolate({
        inputRange: [0, 1],
        outputRange: [0, maxHeight],
    });

    const shorten = (s: string) => {
        if (s.length > 15) {
            return `${s.substring(0, 15)}..`;
        }
        return s;
    };

    return (
        <View {...props} style={containerStyle}>
            <View style={style.filterDropdowns}>
                { orderedFilters.map((filter, index) => (
                    <TouchableOpacity style={style.filterDropdown} key={filter.name} onPress={updateSelectedDropdown(index)}>
                        <ThemedText type="bold" style={style.filterDropdownText}>
                            { shorten(selectedOptions[filter.identifier] !== -1 ? filter.options[selectedOptions[filter.identifier]].name : filter.name) }
                        </ThemedText>

                        <View style={[style.dropdownIconContainer, index === selectedDropdown && filtersVisible ? style.dropdownIconContainerActive : null]}>
                            <DropdownArrow style={style.filterDropdownIcon}/>
                        </View>
                    </TouchableOpacity>
                ))}
            </View>

            <Animated.View style={[style.crop, {height: filtersHeight}]}>
                <View style={style.filterOptions} onLayout={updateMaxHeight}>
                    <TouchableOpacity style={[style.filterOption, style.fullWidth]} onPress={updateSelectedOption(selectedDropdown, -1)}>
                        <ThemedText style={style.filterOptionText}
                            type={selectedOptions[orderedFilters[selectedDropdown].identifier] === -1 ? 'bold' : 'light'}>
                            { orderedFilters[selectedDropdown].unsetName }
                        </ThemedText>
                    </TouchableOpacity>

                    { orderedFilters[selectedDropdown].options.map((option, index) => 
                        <TouchableOpacity style={style.filterOption} key={option.name} onPress={updateSelectedOption(selectedDropdown, index)}>
                            { option.icon
                                ? <option.icon style={style.filterOptionIcon}/>
                                : null
                            }
                            <ThemedText style={style.filterOptionText} numberOfLines={1}
                                type={selectedOptions[orderedFilters[selectedDropdown].identifier] === index ? 'bold' : 'light'}>
                                { option.name }
                            </ThemedText>
                        </TouchableOpacity>
                    )}
                </View>
            </Animated.View>
        </View>
    )
};

export { Filters };