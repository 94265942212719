export default {
    emptyResult: 'No objects match current filters.',

    // Area
    area: 'Area',
    areaAll: 'All areas',

    // Length
    length: 'Length',
    lengthAll: 'All lengths',

    // Difficulty
    difficulty: 'Difficulty',
    difficultyAll: 'All difficulties',

    // Status
    status: 'Status',
    statusAll: 'All statuses',
    statusOpen: 'Open',
    statusClosed: 'Closed',
    statusGroomedToday: 'Groomed today',
    statusGroomedYesterdayOrToday: 'Groomed yesterday or today',
};