import {PathGpsCoordinate} from '../../interfaces';
import React from 'react';
import {View, ViewProps} from 'react-native';
import {useTranslation} from 'react-i18next';
import {
    getElevationSpan,
    getMaxElevation,
    getMinElevation,
} from '../../utils';
import { elevationGraphStyle, elevationGraphColors } from './elevation-graph.style';
import { useThemedStyleFunction } from '../theme-provider';
import { withExternalStyle } from '../../utils';
import { cloneDeep } from 'lodash';

type ElevationGraphProps = {
    reversed: boolean;
    gpsCoords: PathGpsCoordinate[];
    closestIndex: number;
} & ViewProps;

const getLineHeight = (min: number, max: number, span: number) => {
    if (max === 0 || min === 0 || span === 0) {
        return 0;
    }
    return (max - min) * ((100 / (span + 100)) * 14);
};

const ElevationGraph = (props: ElevationGraphProps) => {
    const {gpsCoords, closestIndex, reversed, style: styleProp} = props;

    const [t] = useTranslation('common');

    const profileWidth = 7000;
    const profileHeight = 1800;

    const minHeight = getMinElevation(gpsCoords);
    const maxHeight = getMaxElevation(gpsCoords);
    const elevationSpan = getElevationSpan(minHeight, maxHeight);

    const lineHeight = getLineHeight(minHeight, maxHeight, elevationSpan);

    const MinHeightText = `${minHeight} ${t('elevationAboveSeaLevel')}`;
    const MaxHeightText = `${maxHeight} ${t('elevationAboveSeaLevel')}`;

    const style = useThemedStyleFunction(elevationGraphStyle);
    const svgColors = useThemedStyleFunction(elevationGraphColors);
    const containerStyle = withExternalStyle(style.container, styleProp);

    const getReversibleIndex = (i: number): number => {
        return reversed ? gpsCoords.length - 1 - i : i;
    };
    const coordinates = reversed ? cloneDeep(gpsCoords).reverse() : gpsCoords;

    return (
        <View style={containerStyle}>
            <svg
                viewBox={`0 0 ${profileWidth} ${profileHeight}`}
                style={style.svg}>
                <polyline
                    stroke={svgColors.graph.stroke}
                    fill={svgColors.graph.fill}
                    strokeWidth="3"
                    points={`${coordinates.map(
                        (coord: PathGpsCoordinate, index: number) =>
                        `${
                            (profileWidth / coordinates.length +
                            profileWidth / coordinates.length / coordinates.length) *
                            index
                        }, 
                        ${
                            profileHeight -
                            350 -
                            (coord.elevation - minHeight) *
                            ((100 / (elevationSpan + 100)) * 14)
                        }`,
                    )},
                    ${profileWidth}, ${profileHeight}, 0, ${profileHeight}`}
                />

                <line
                    stroke={svgColors.max.stroke}
                    fill={svgColors.max.fill}
                    x1={0}
                    y1={profileHeight - 350 - lineHeight}
                    x2={profileWidth}
                    y2={profileHeight - 350 - lineHeight}
                    strokeWidth="30"
                    strokeLinecap="round"
                    strokeDasharray="1, 80"
                />

                <text
                    stroke={svgColors.max.stroke}
                    fill={svgColors.max.fill}
                    strokeWidth="8"
                    fontSize="215"
                    fontStretch="normal"
                    x={profileWidth - 40}
                    y={profileHeight - 400 - lineHeight}
                    textAnchor="end">
                    {MaxHeightText}
                </text>

                <line
                    stroke={svgColors.min.stroke}
                    fill={svgColors.min.fill}
                    x1={0}
                    y1={profileHeight - 350}
                    x2={profileWidth}
                    y2={profileHeight - 350}
                    strokeWidth="30"
                    strokeLinecap="round"
                    strokeDasharray="1, 80"
                />

                <text
                    stroke={svgColors.min.stroke}
                    fill={svgColors.min.fill}
                    strokeWidth="8"
                    fontSize="215"
                    fontStretch="normal"
                    x={profileWidth - 40}
                    y={profileHeight - 100}
                    textAnchor="end">
                    {MinHeightText}
                </text>

                {closestIndex !== -1 && (
                    <g transform={`translate(
                        ${(profileWidth / coordinates.length + profileWidth / coordinates.length / coordinates.length) * getReversibleIndex(closestIndex)},
                        ${profileHeight - 350 - (coordinates[getReversibleIndex(closestIndex)].elevation - minHeight) * ((100 / (elevationSpan + 100)) * 14)}
                    )`}>
                        <circle cx="0" cy="0" r="200" fill={svgColors.point.fill} />
                        <path
                            fill="none"
                            stroke={svgColors.point.stroke}
                            strokeWidth="30"
                            d="M-50,-100L50,0L-50,100"
                        />
                    </g>
                )}
            </svg>
        </View>
    );
};

export { ElevationGraph };
