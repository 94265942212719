import {StyleSheet} from 'react-native';
import { Theme } from '../theme-provider';
  
const elevationGraphStyle = (theme: Theme) => StyleSheet.create({
    container: {
        aspectRatio: 3.8888,
        backgroundColor: theme.color.graphBackground,
    },
    svg: {
        display: 'flex',
    }
});

const elevationGraphColors = (theme: Theme) => ({
    graph: {
        fill: theme.color.graphForeground,
        stroke: theme.color.graphForeground,
    },
    max: {
        stroke: theme.color.graphPrimaryOverlay,
        fill: theme.color.graphPrimaryOverlay,
    },
    min: {
        stroke: theme.color.graphSecondaryOverlay,
        fill: theme.color.graphSecondaryOverlay,
    },
    point: {
        fill: theme.color.graphSecondaryOverlay,
        stroke: theme.color.graphForeground,
    },
});
  
export { elevationGraphStyle, elevationGraphColors };