export default {
    emptyResult: 'Inga objekt matchar nuvarande filter.',

    // Area
    area: 'Område',
    areaAll: 'Alla områden',

    // Length
    length: 'Längd',
    lengthAll: 'Alla längder',

    // Difficulty
    difficulty: 'Svårighetsgrad',
    difficultyAll: 'Alla svårighetsgrader',

    // Status
    status: 'Status',
    statusAll: 'Alla statusar',
    statusOpen: 'Öppna',
    statusClosed: 'Stängda',
    statusGroomedToday: 'Pistade idag',
    statusGroomedYesterdayOrToday: 'Pistade senast igår',
};