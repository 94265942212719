import { Platform } from "react-native";

const boxShadow = (elevation: number, color: string, androidColor?: string, iosColor?: string) => {
    switch(Platform.OS) {
        case 'ios':
            return {
                shadowColor: iosColor ?? color,
                shadowOffset: {width: 0, height: Math.round(elevation / 2)},
                shadowOpacity: 1,
                shadowRadius: elevation,
            };

        case 'android':
            return {
                shadowColor: androidColor ?? color,
                elevation: elevation,
            };

        case 'web':
            return {
                'box-shadow': `${color} 0px ${Math.round(elevation / 2)}px ${elevation}px`,
            };
    }
};

const withExternalStyle = (internal: any, external: any) => external ?
    Array.isArray(external) ?
        [internal, ...external] :
        [internal, external] :
    internal;

export { boxShadow, withExternalStyle };
