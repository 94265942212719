import { create } from 'zustand';

interface ImageViewer {
    // Image Viewer data
    images: string[];
    visible?: boolean;
    current?: number;

    showImageViewer: (images: string[], current: number) => void;
    hideImageViewer: () => void;
};

/**
 * Hook for accessing the image viewer data store
 */
const useImageViewerStore = create<ImageViewer>((set) => ({
    images: [],
    showImageViewer: (images: string[], current: number) => set({
        visible: true,
        images,
        current,
    }),
    hideImageViewer: () => set({
        visible: false,
        images: undefined,
        current: undefined,
    }),
}));

export { ImageViewer, useImageViewerStore };